import * as Sentry from "@sentry/react";
import { currentFrontEndVersion } from '../../App';
import IUser from '../../Interfaces/IUser';

Sentry.init({
    dsn: "https://4df79f3b3c2243f3b58764ce23d3943a@stereotyped-carp.pikapod.net/3",
    environment: process.env.NODE_ENV,
    tracesSampleRate: 1.0,
    ignoreErrors: [
        "Cannot read property 'attempts' of undefined",
        "aa[e] is undefined",
        "ra[e] is undefined",
        "AxiosError: Network Error"
    ],
    enabled: process.env.NODE_ENV && process.env.NODE_ENV !== 'development',
});

class ErrorReporter {

    constructor() {
        // Sentry is initialized in Sentry.init(), so no constructor setup is needed.
    }

    setUser(user: IUser) {
        Sentry.setUser({
            id: user.id,
            email: user.email,
        });
    }

    info(message: string) {
        var obj = { frontendVersion: currentFrontEndVersion };
        Sentry.captureMessage(message, {
            level: 'info',
            extra: obj,
        });
    }

    critical(message: string) {
        var obj = { frontendVersion: currentFrontEndVersion };
        Sentry.captureMessage(message, {
            level: 'fatal',
            extra: obj,
        });
    }

    error(message: string) {
        var obj = { frontendVersion: currentFrontEndVersion };
        Sentry.captureException(new Error(message), {
            extra: obj,
        });
    }

    warning(message: string) {
        var obj = { frontendVersion: currentFrontEndVersion };
        Sentry.captureMessage(message, {
            level: 'warning',
            extra: obj,
        });
    }
}

export default new ErrorReporter();
